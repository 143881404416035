<template lang='pug'>
	.footer
		.top.gutter
			.badges
				.container
					img.green(src='/badges/travel-blue-en.png' alt='tour operator' title='tour operator')
					img.blue(src='/badges/travel-green-en.png' alt='travel agency' title='travel agency')
			.links
				.left
					h4.title {{ $store.state.navigation.company[$store.state.language] }}
					p.router-link.sans.clickable(@click='routeTo("home")') {{ $store.state.navigation.home[$store.state.language] }}
					p.router-link.sans.clickable(@click='routeTo("about")') {{ $store.state.navigation.about[$store.state.language] }}
					p.router-link.sans.clickable(@click='routeTo("tours")') {{ $store.state.navigation.tour[$store.state.language] }}
					p.router-link.sans.clickable(@click='routeTo("contact")') {{ $store.state.navigation.contact[$store.state.language] }}
				.right
					h4.title {{ $store.state.navigation.useful[$store.state.language] }}
					p.router-link.sans.clickable(@click='routeTo("blog")') {{ $store.state.navigation.blog[$store.state.language] }}
					a.router-link.sans.clickable(href='https://www.ferdamalastofa.is/en/about-us/icelandic-tourist-board' target='_blank') {{ $store.state.navigation.tourist[$store.state.language] }}
			//-.sub
				.title {{ $store.state.navigation.touch[$store.state.language] }}
				.contact
					input.sans(type='text' :placeholder='$store.state.navigation.email[$store.state.language]')
					.subscribe-button.clickable {{ $store.state.navigation.subscribe[$store.state.language] }}
		.hr
		.bottom.gutter
			.copy © 2019 New Horizons
			.links
				.router-link
					p.clickable(@click='openPrivacy') {{ $store.state.navigation.privacy[$store.state.language] }}
				.router-link
					p.clickable(@click='openTerms') {{ $store.state.navigation.terms[$store.state.language] }}
				.router-link
					p.clickable(@click='openCookies') {{ $store.state.navigation.cookies[$store.state.language] }}

</template>

<script>
export default {
	name: 'Footer',
	methods: {
		openPrivacy: function () {
			this.$modal.show('privacy');
		},
		openTerms: function () {
			this.$modal.show('terms');
		},
		openCookies: function () {
			this.$modal.show('cookies');
		},
		routeTo: function (destination) {
			let lang = 'en';
			if (this.$store.state.language === 'chinese') {
				lang = 'cn';
			}
			this.$router.push({
				name: destination,
				params: {
					language: lang
				}
			});
		}
	}
};
</script>

<style scoped lang='less'>
@color-main: #1728B0;
.footer {
	background-color: #F8F8F8;
	.bottom {
		height: 97px;
		display: grid;
		grid-template-columns: 2fr 1fr;
		.copy {
			line-height: 97px;
		}
		.links {
			line-height: 97px;
			display: grid;
			grid-template-columns: auto auto auto;
			.router-link {
				text-align: center;
				.clickable {
					display: inline-block;
				}
			}
		}
	}
	.hr {
		border-top: 1px solid #E2E2E2;
	}
	.top {
		height: 333px;
		display: grid;
		grid-template-columns: 1.5fr 1.5fr 2fr;
		.badges {
			.container {
				position: relative;
				display: inline-block;
				left: 50%;
				top: 50%;
				transform: translate3d(-50%, -50%, 0px);
				img {
					width: 115px;
				}
			}
		}
		.links {
			display: grid;
			grid-template-columns: 1fr 1fr;
			.left {
				position: relative;
				top: 50%;
				transform: translate3d(0px, -50%, 0px);
				height: 200px;
				.title {
					font-weight: bold;
					margin-bottom: 41px;
					color: #343434;
				}
				.router-link {
					color: #868686;
					font-size: 14px;
					line-height: 30px;
				}
			}
			.right {
				position: relative;
				top: 50%;
				transform: translate3d(0px, -50%, 0px);
				height: 200px;
				.title {
					font-weight: bold;
					margin-bottom: 41px;
					color: #343434;
				}
				.router-link {
					color: #868686;
					font-size: 14px;
					line-height: 30px;
				}
			}
		}
		.sub {
			position: relative;
			top: 50%;
			transform: translate3d(0px, -50%, 0px);
			height: 200px;
			.title {
				color: #343434;
				margin-bottom: 34px;
			}
			.contact {
				display: grid;
				grid-template-columns: 1.5fr 1fr;
				grid-gap: 24px;
				input {
					box-sizing: border-box;
					width: 100%;
					height: 45px;
					background-color: white;
					outline: none;
					border: none;
					padding-left: 19px;
					padding-right: 19px;
					line-height: 45px;
					&::placeholder {
						color: #868686;
					}
				}
				.subscribe-button {
					outline: 1px solid @color-main;
					font-weight: bold;
					width: 100%;
					height: 45px;
					line-height: 45px;
					text-align: center;
				}
			}
		}
	}
	@media screen and (max-width: 1366px) {
		.top {
			height: auto;
			padding-top: 38px;
			grid-template-columns: 1fr 1fr;
			.badges {
				.container {
					top: 50%;
					left: 0%;
					transform: translate3d(0%, -50%, 0px);
					img {
						width: 145px;
					}
				}
			}
			.links {
				grid-template-columns: 150px 150px;
			}
			.sub {
				margin-top: 38px;
			}
		}
	}
	@media screen and (max-width: 968px) {
		.top {
			grid-template-columns: 1fr;
			.badges {
				.container {
					left: 50%;
					transform: translate3d(-50%, -50%, 0px);
					img {
						width: 145px;
					}
				}
			}
			.links {
				margin-top: 38px;
				margin-left: auto;
				margin-right: auto;
				grid-template-columns: 1fr 1fr;
				grid-gap: 34px;
				.left {
					left: 50%;
					transform: translate3d(-50%, -50%, 0px);
				}
				.right {
					left: 50%;
					transform: translate3d(-50%, -50%, 0px);
				}
			}
			.sub {
				max-width: 400px;
				margin-left: auto;
				margin-right: auto;
				.title {
					text-align: center;
				}
				.contact {
					display: grid;
					grid-template-columns: 1fr;
					grid-gap: 24px;
				}
				padding-bottom: 38px;
			}
		}
	}
	@media screen and (max-width: 1186px) {
		.bottom {
			grid-template-columns: 300px auto;
			.links {
				line-height: 97px;
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				.router-link {
					text-align: right;
				}
			}
		}
	}
	@media screen and (max-width: 816px) {
		.bottom {
			height: auto;
			grid-template-columns: 1fr;
			.copy {
				text-align: center;
			}
			.links {
				.router-link {
					text-align: center;
				}
			}
		}
	}
	@media screen and (max-width: 536px) {
		.bottom {
			padding-bottom: 38px;
			.links {
				line-height: 46px;
				display: grid;
				grid-template-columns: 1fr;
			}
		}
	}
	@media screen and (max-width: 340px) {
		.top {
			.badges {
				.container {
					display: grid;
					grid-template-columns: 1fr;
					img {
						margin-left: auto;
						margin-right: auto;
					}
				}
			}
		}
	}
}
</style>
