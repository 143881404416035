<template lang='pug'>
	.nav
		.gutter
			.logo-container
				img.logo.clickable(src='@/assets/logo.png' alt='n66.is' title='n66.is' @click='routeTo("home")')
			.nav-container
				.router-link.clickable.desktop(@click='routeTo("tours")' :class='{ active: active === "tours" }')
					.over
					.text {{ $store.state.navigation.tour[$store.state.language] }}
				.router-link.clickable.desktop(@click='routeTo("about")' :class='{ active: active === "about" }')
					.over
					.text {{ $store.state.navigation.about[$store.state.language] }}
				.router-link.clickable.desktop(@click='routeTo("blog")' :class='{ active: active === "blog" }')
					.over
					.text {{ $store.state.navigation.blog[$store.state.language] }}
				.router-link.clickable.desktop(@click='routeTo("contact")' :class='{ active: active === "contact" }')
					.over
					.text {{ $store.state.navigation.contact[$store.state.language] }}
				.language-selection
					img.flag.clickable(src='@/assets/china.svg' alt='chinese' title='chinese' v-if='$store.state.language === "english"' @click='swapLanguage("chinese")')
					img.flag.clickable(src='@/assets/en.svg' alt='english' title='english' v-else @click='swapLanguage("english")')
				.burger.mobile
					img.clickable(src='/burger.svg' @click='showSide' alt='menu' title='menu')
</template>

<script>
export default {
	name: 'Nav',
	data: function () {
		return {
			active: 'home'
		};
	},
	watch: {
		'$route' (to, from) {
			if (this.$route.path.includes('tours')) {
				this.active = 'tours';
			} else if (this.$route.path.includes('about')) {
				this.active = 'about';
			} else if (this.$route.path.includes('blog')) {
				this.active = 'blog';
			} else if (this.$route.path.includes('contact')) {
				this.active = 'contact';
			} else {
				this.active = 'home';
			}
		}
	},
	methods: {
		swapLanguage: function (language) {
			let lang = 'en';
			if (language === 'chinese') {
				lang = 'cn';
			}
			this.$router.push({
				name: this.$route.name,
				params: {
					language: lang
				}
			});
			location.reload();
		},
		showSide: function () {
			this.$emit('showSide');
		},
		routeTo: function (destination) {
			let lang = 'en';
			if (this.$store.state.language === 'chinese') {
				lang = 'cn';
			}
			this.$router.push({
				name: destination,
				params: {
					language: lang
				}
			});
		}
	}
};
</script>

<style scoped lang='less'>
@color-main: #1728B0;
.nav {
	position: fixed;
	z-index: 300;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 122px;
	background-color: white;
	box-shadow: 0 4px 2px -2px rgba(0,0,0,.2);
	.gutter {
		height: 100%;
		display: grid;
		grid-template-columns: auto auto;
		.nav-container {
			height: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			.language-selection {
				.flag {
					position: relative;
					top: 50%;
					left: 100%;
					transform: translate3d(-100%, -50%, 0px);
					height: 28px;
					width: 43px;
				}
			}
			.router-link {
				position: relative;
				.text {
					z-index: 3;
					display: block;
					position: relative;
					line-height: 122px;
					color: black;
					font-weight: bold;
					text-align: center;
					text-decoration: none;
					text-transform: uppercase;
					transition: .3s;
				}
				.over {
					position: absolute;
					top: 0px;
					left: 50%;
					transform: translate3d(-50%, 0px, 0px);
					width: 80%;
					height: 100%;
					&:after {
						z-index: 1;
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
						content: ' ';
						margin: auto;
						width: 100%;
						height: 0px;
						background: @color-main;
						transform: rotate(-8deg);
						transition: .2s;
					}
				}
				&:hover {
					z-index: 3;
					.text {
						color: white;
					}
					.over {
						&:after {
							height: 40%;
							transform: rotate(0deg);
						}
					}
				}
				&.active {
					.text {
						color: white;
					}
					.over {
						&:after {
							height: 40%;
							transform: rotate(0deg);
						}
					}
				}
			}
		}
		.logo-container {
			height: 100%;
			.logo {
				display: block;
				position: relative;
				height: 71px;
				top: 50%;
				transform: translate3d(0px, -50%, 0px);
			}
		}
	}
	.mobile {
		display: none;
	}
	@media screen and (max-width: 1365px) {
		.desktop {
			display: none;
		}
		.mobile {
			display: initial;
		}
		.gutter {
			.nav-container {
				grid-template-columns: auto 33px;
				grid-column-gap: 35px;
			}
		}
		.burger {
			img {
				display: block;
				position: relative;
				top: 50%;
				left: 100%;
				transform: translate3d(-100%, -50%, 0px);
				width: 33px;
			}
		}
	}
	@media screen and (max-width: 520px) {
		height: 87px;
	}
}
</style>
