<template lang='pug'>
	.home
		.description {{ $store.state.seo.description[$store.state.language] }}
		HeaderHome
		Grid
		Horizon
</template>

<script>
export default {
	name: 'Home',
	computed: {
		abLang: function () {
			if (this.$store.state.language === 'english') {
				return 'en';
			} else {
				return 'cn';
			}
		}
	},
	metaInfo: function () {
		return {
			title: 'New Horizons',
			description: this.$store.state.seo.description[this.$store.state.language],
			link: [
				{ rel: 'canonical', href: `https://n66.is/${this.abLang}` }
			],
			meta: [
				{
					itemprop: 'keywords',
					name: 'keywords',
					property: 'keywords',
					content: this.$store.state.seo.keywords[this.$store.state.language]
				},
				{
					itemprop: 'og:site_name',
					name: 'og:site_name',
					property: 'og:site_name',
					content: 'New Horizons'
				},
				{
					itemprop: 'og:url',
					name: 'og:url',
					property: 'og:url',
					content: `https://n66.is/${this.abLang}/index.html`
				},
				{
					itemprop: 'og:image',
					name: 'og:image',
					property: 'og:image',
					content: 'https://n66.is/header.jpg'
				},
				{
					itemprop: 'og:title',
					name: 'og:title',
					property: 'og:title',
					content: 'New Horizons'
				},
				{
					itemprop: 'og:description',
					name: 'og:description',
					property: 'og:description',
					content: this.$store.state.seo.description[this.$store.state.language]
				},
				{
					itemprop: 'description',
					name: 'description',
					property: 'description',
					content: this.$store.state.seo.description[this.$store.state.language]
				}
			]
		};
	}
};
</script>

<style lang="less" scoped>
.home {
	margin-bottom: 65px;
}
</style>
