<template lang='pug'>
	.about
		.description {{ $store.state.seo.description[$store.state.language] }}
		HeaderAbout
		.top.gutter
			.content
				.title {{ $store.state.about.main.title[$store.state.language] }}
				.para.sans.main {{ $store.state.about.main.paraOne[$store.state.language] }}
				.subtitle {{ $store.state.about.main.subTitleOne[$store.state.language] }}
				.para.sans {{ $store.state.about.main.paraTwo[$store.state.language] }}
				.subtitle {{ $store.state.about.main.subTitleTwo[$store.state.language] }}
				.para.sans {{ $store.state.about.main.paraThree[$store.state.language] }}
				.find-trips-button.clickable(@click='routeTo("tours")') {{ $store.state.about.main.viewTrips[$store.state.language] }}
			.image
				img(src='@/assets/about-us-img.jpg' alt='volcano' title='volcano')
		.bottom.gutter
			.item
				//- img(src='@/assets/badges/1.png' :alt='$store.state.about.main.subTitleThree[$store.state.language]' :title='$store.state.about.main.subTitleThree[$store.state.language]')
				img(src='/badges/ferdamalastofa.jpeg' :alt='$store.state.about.main.subTitleThree[$store.state.language]' :title='$store.state.about.main.subTitleThree[$store.state.language]')
				.title {{ $store.state.about.main.subTitleThree[$store.state.language] }}
				.para.sans {{ $store.state.about.main.paraFour[$store.state.language] }}
			.item
				img(src='@/assets/badges/2.png' :alt='$store.state.about.main.subTitleFour[$store.state.language]' :title='$store.state.about.main.subTitleFour[$store.state.language]')
				.title {{ $store.state.about.main.subTitleFour[$store.state.language] }}
				.para.sans {{ $store.state.about.main.paraFive[$store.state.language] }}
			.item
				img(src='@/assets/badges/3.png' :alt='$store.state.about.main.subTitleFive[$store.state.language]' :title='$store.state.about.main.subTitleFive[$store.state.language]')
				.title {{ $store.state.about.main.subTitleFive[$store.state.language] }}
				.para.sans {{ $store.state.about.main.paraSix[$store.state.language] }}
			.item
				img(src='@/assets/badges/4.png' :alt='$store.state.about.main.subTitleSix[$store.state.language]' :title='$store.state.about.main.subTitleSix[$store.state.language]')
				.title {{ $store.state.about.main.subTitleSix[$store.state.language] }}
				.para.sans {{ $store.state.about.main.paraSeven[$store.state.language] }}
</template>

<script>
export default {
	name: 'About',
	computed: {
		abLang: function () {
			if (this.$store.state.language === 'english') {
				return 'en';
			} else {
				return 'cn';
			}
		}
	},
	metaInfo: function () {
		return {
			title: this.$store.state.about.title[this.$store.state.language],
			link: [
				{ rel: 'canonical', href: `https://n66.is/${this.abLang}/about/` }
			],
			meta: [
				{
					name: 'keywords',
					property: 'keywords',
					content: this.$store.state.seo.keywords[this.$store.state.language]
				},
				{
					name: 'og:site_name',
					property: 'og:site_name',
					content: 'New Horizons'
				},
				{
					name: 'og:url',
					property: 'og:url',
					content: `https://n66.is/${this.abLang}/about/index.html`
				},
				{
					name: 'og:image',
					property: 'og:image',
					content: 'https://n66.is/header.jpg'
				},
				{
					name: 'og:title',
					property: 'og:title',
					content: this.$store.state.about.title[this.$store.state.language]
				},
				{
					name: 'og:description',
					property: 'og:description',
					content: this.$store.state.seoAbout.description[this.$store.state.language]
				},
				{
					name: 'description',
					property: 'description',
					content: this.$store.state.seoAbout.description[this.$store.state.language]
				}
			]
		};
	},
	methods: {
		routeTo: function (destination) {
			let lang = 'en';
			if (this.$store.state.language === 'chinese') {
				lang = 'cn';
			}
			this.$router.push({
				name: destination,
				params: {
					language: lang
				}
			});
		}
	}
};
</script>

<style lang="less" scoped>
@color-main: #1728B0;
.about {
	margin-bottom: 65px;
	.top {
		margin-top: 100px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 60px;
		.image {
			position: relative;
			max-width: 100%;
			img {
				position: relative;
				right: 0px;
				width: 100%;
				max-width: 545px;
				height: 464px;
				object-fit: cover;
			}
		}
		@media screen and (max-width: 968px) {
			grid-template-columns: 1fr;
			grid-row-gap: 60px;
			.image {
				box-sizing: border-box;
				img {
					right: auto;
					max-width: 100%;
					height: auto;
				}
			}
		}
		.content {
			.title {
				font-weight: bold;
				font-size: 25px;
				margin-bottom: 42px;
			}
			.para {
				color: rgba(0,0,0,.7);
				&.main {
					max-width: 500px;
					margin-bottom: 50px;
				}
			}
			.subtitle {
				font-weight: bold;
				font-size: 21px;
				color: rgb(51,51,51);
				margin-top: 16px;
				margin-bottom: 8px;
			}
			.find-trips-button {
				margin-top: 42px;
				width: 304px;
				height: 46px;
				text-align: center;
				font-weight: bold;
				font-size: 20px;
				color: white;
				background-color: @color-main;
				line-height: 46px;
				@media screen and (max-width: 350px) {
					width: 100%;
				}
			}
		}
	}
	.bottom {
		margin-top: 100px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-column-gap: 92px;
		@media screen and (max-width: 968px) {
			grid-template-columns: 1fr 1fr;
			grid-row-gap: 92px;
		}
		@media screen and (max-width: 420px) {
			grid-template-columns: 1fr;
			grid-row-gap: 92px;
		}
		.item {
			.title {
				font-size: 18px;
				font-weight: bold;
				text-align: center;
				margin-top: 58px;
				margin-bottom: 28px;
			}
			.para {
				text-align: center;
				color: rgba(0,0,0,.7);
			}
			img {
				width: 100%;
			}
		}
	}
}
</style>
