<template lang='pug'>
	.book-now-bottom(v-if='isScrolled')
		script(
			type="application/javascript"
			:src="scriptSrc"
		)
		.gutter
			.text {{ title }}
			button(
				class="bokunButton"
				disabled
				:id="buttonId"
				:data-src="dataSrc"
			) {{ buttonText }}
</template>

<script>
export default {
	props: {
		page: {
			default: '',
			type: String
		}
	},
	data: function () {
		return {
			isScrolled: false
		};
	},
	computed: {
		title: function () {
			const title = this.$store.state[this.page].title[this.$store.state.language];
			return title;
		},
		buttonText: function () {
			const text = this.$store.state.bookNowBottom.buttonMessage[this.$store.state.language];
			return text;
		},
		scriptSrc: function () {
			const src = this.$store.state.bookNowBottom.widgetScriptSrc[this.$store.state.language];
			return src;
		},
		buttonId: function () {
			const id = this.$store.state[this.page].bookNowButtonId[this.$store.state.language];
			return id;
		},
		dataSrc: function () {
			const src = this.$store.state[this.page].bookNowButtonDataSrc[this.$store.state.language];
			return src;
		}
	},
	beforeMount: function () {
		window.addEventListener('scroll', this.ScrolledWindow);
	},
	beforeDestroy: function () {
		document.querySelector('.footer').style.marginBottom = '0';
		window.removeEventListener('scroll', this.ScrolledWindow);
	},
	methods: {
		ScrolledWindow: function () {
			if (window.scrollY > 200) {
				this.isScrolled = true;
				window.removeEventListener('scroll', this.ScrolledWindow);
				document.querySelector('.footer').style.marginBottom = '80px';
			}
		}
	}
};
</script>

<style lang='less'>
.book-now-bottom {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 80px;
	background-color: white;
	z-index: 300;
	box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
	.gutter {
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media screen and (max-width: 768px) {
			justify-content: center;
		}
		.text {
			color: black;
			font-weight: bold;
			font-size: 18px;
			font-family: sans-serif;
			@media screen and (max-width: 768px) {
				display: none;
			}
		}
		.bokunButton {
			display: inline-block;
			padding: 15px 30px;
			background: #1728b0;
			border: none;
			border-radius: 0px;
			box-shadow: none;
			font-weight: 600;
			font-size: 14px;
			text-decoration: none;
			text-align: center;
			color: #FFFFFF;
			font-family: sans-serif;
			cursor: pointer;
			margin-left: 20px;
			white-space: nowrap;
			@media screen and (max-width: 520px) {
				width: 100%;
				margin: 0;
			}
		}
		.bokunButton:hover {
			background: #0e196c;
		}
		.bokunButton:active {
			background: #111e83;
		}
	}
}
</style>
