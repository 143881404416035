<template lang='pug'>
	.side(:class='{ show: active }' v-click-outside='hide')
		router-link.router-link.clickable(to='tours') {{ $store.state.navigation.tour[$store.state.language] }}
		router-link.router-link.clickable(to='about') {{ $store.state.navigation.about[$store.state.language] }}
		router-link.router-link.clickable(to='blog') {{ $store.state.navigation.blog[$store.state.language] }}
		router-link.router-link.clickable(to='contact') {{ $store.state.navigation.contact[$store.state.language] }}
</template>

<script>
import ClickOutside from 'vue-click-outside';
export default {
	name: 'Side',
	directives: {
		ClickOutside
	},
	props: {
		active: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		hide: function () {
			this.$emit('hide');
		},
		routeTo: function (destination) {
			this.$emit('hide');
			this.$router.push(destination);
		}
	}
};
</script>

<style scoped lang='less'>
.side {
	transition: .3s;
	transform: translate3d(100%, 0px, 0px);
	&.show {
		transform: translate3d(0%, 0px, 0px);
	}
	padding-top: 150px;
	padding-left: 26px;
	z-index: 100;
	position: fixed;
	top: 0px;
	right: 0px;
	height: 100%;
	width: 100%;
	max-width: 260px;
	background-color: black;
	.router-link {
		display: block;
		color: white;
		font-weight: bold;
		font-size: 25px;
		transition: .3s;
		&:hover {
			transform: translate3d(20px, 0px, 0px);
		}
	}
}
</style>
