<template lang='pug'>
	.header
		img(src='@/assets/header.jpg' :alt='$store.state.header.title[$store.state.language]' :title='$store.state.header.title[$store.state.language]')
		.text-content
			h1.title.gutter {{ $store.state.header.title[$store.state.language] }}
			h2.sub-title.sans {{ $store.state.header.subTitle[$store.state.language] }}
		.trips-button.clickable(@click='routeTo("tours")') {{ $store.state.header.viewAll[$store.state.language] }}
</template>

<script>
export default {
	name: 'HeaderHome',
	methods: {
		routeTo: function (destination) {
			let lang = 'en';
			if (this.$store.state.language === 'chinese') {
				lang = 'cn';
			}
			this.$router.push({
				name: destination,
				params: {
					language: lang
				}
			});
		}
	}
};
</script>

<style scoped lang='less'>
@color-main: #1728B0;
.header {
	background-color: #F8F8F8;
	position: relative;
	.trips-button {
		width: 168px;
		height: 50px;
		background-color: @color-main;
		font-weight: bold;
		font-size: 15px;
		color: white;
		text-align: center;
		line-height: 50px;
		margin-left: auto;
		margin-right: auto;
		transform: translate3d(0px, -50%, 0px);
		transition: .3s;
		outline: 2px solid rgba(0,0,0,0);
		&:hover {
			width: 200px;
			color: @color-main;
			background-color: white;
			outline: 2px solid @color-main;
		}
	}
	img {
		max-height: 476px;
		width: 100%;
		object-fit: cover;
		filter: brightness(.5);
		@media screen and (max-width: 1365px) {
			height: 544px;
		}
	}
	.text-content {
		box-sizing: border-box;
		position: absolute;
		width: 100%;
		top: 50%;
		padding-left: 24px;
		padding-right: 24px;
		transform: translate3d(0px, -50%, 0px);
		.title {
			font-size: 50px;
			text-align: center;
			font-weight: bold;
			color: white;
			@media screen and (max-width: 1365px) {
				font-size: 42px;
			}
		}
		.sub-title {
			font-weight: normal;
			margin-left: auto;
			margin-right: auto;
			max-width: 466px;
			font-size: 20px;
			text-align: center;
			color: white;
			@media screen and (max-width: 1365px) {
				font-size: 16px;
			}
		}
	}
}
</style>
