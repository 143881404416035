<template lang='pug'>
	.tour
		.description {{ $store.state.fourDaysSouthernIceland.bullet1[$store.state.language] }}
		HeaderSingleTour(:image='"/trips/1.jpeg"' :title='$store.state.fourDaysSouthernIceland.title[$store.state.language]')
		.container#bokun-container.gutter(:class='{ sticky: sticky }')
			.bokun#bokun-w80739_a72fd5c7_2756_4a33_8f0c_5cbb7189f174(v-if='$store.state.language === "english"') Loading...
			.bokun#bokun-w80739_44f12282_3fcb_4a67_9fe3_f7ae1d348dc8(v-else) Loading...
		BookNowBottom(:page='pageName')
</template>

<script>
export default {
	name: 'Home',
	data: function () {
		return {
			pageName: 'fourDaysSouthernIceland',
			sticky: false
		};
	},
	computed: {
		abLang: function () {
			if (this.$store.state.language === 'english') {
				return 'en';
			} else {
				return 'cn';
			}
		}
	},
	metaInfo: function () {
		return {
			title: this.$store.state.fourDaysSouthernIceland.title[this.$store.state.language],
			link: [
				{ rel: 'canonical', href: `https://n66.is/${this.abLang}/tours/golden-circle` }
			],
			meta: [
				{
					name: 'keywords',
					property: 'keywords',
					content: this.$store.state.seo.keywords[this.$store.state.language]
				},
				{
					name: 'og:site_name',
					property: 'og:site_name',
					content: 'New Horizons'
				},
				{
					name: 'og:url',
					property: 'og:url',
					content: `https://n66.is/${this.abLang}/tours/golden-circle/index.html`
				},
				{
					name: 'og:image',
					property: 'og:image',
					content: 'https://n66.is/trips/1/header.jpg'
				},
				{
					name: 'og:title',
					property: 'og:title',
					content: this.$store.state.fourDaysSouthernIceland.title[this.$store.state.language]
				},
				{
					name: 'og:description',
					property: 'og:description',
					content: this.$store.state.fourDaysSouthernIceland.seo[this.$store.state.language]
				},
				{
					name: 'description',
					property: 'description',
					content: this.$store.state.fourDaysSouthernIceland.seo[this.$store.state.language]
				}
			]
		};
	},
	created: function () {
		this.$nextTick(() => {
			if (this.$store.state.language === 'english') {
				// TODO: ENGLISH
				let w80739_a72fd5c7_2756_4a33_8f0c_5cbb7189f174; // eslint-disable-line
				(function (d, t) {
					let host = 'widgets.bokun.io';
					let frameUrl = 'https://' + host + '/widgets/80739?bookingChannelUUID=94675dca-b020-42bb-9dae-b9ba24c0548b&amp;activityId=172346&amp;lang=en&amp;ccy=ISK&amp;hash=w80739_a72fd5c7_2756_4a33_8f0c_5cbb7189f174';
					let s = d.createElement(t);
					let options = {
						'host': host,
						'frameUrl': frameUrl,
						'widgetHash': 'w80739_a72fd5c7_2756_4a33_8f0c_5cbb7189f174',
						'autoResize': true,
						'height': '',
						'width': '100%',
						'minHeight': 0,
						'async': true,
						'ssl': true,
						'affiliateTrackingCode': '',
						'transientSession': true,
						'cookieLifetime': 43200
					};
					s.src = 'https://' + host + '/assets/javascripts/widgets/embedder.js';
					s.onload = s.onreadystatechange = function () {
						var rs = this.readyState; if (rs) if (rs != 'complete') if (rs != 'loaded') return; // eslint-disable-line
						try {
							w80739_a72fd5c7_2756_4a33_8f0c_5cbb7189f174 = new BokunWidgetEmbedder(); // eslint-disable-line
							w80739_a72fd5c7_2756_4a33_8f0c_5cbb7189f174.initialize(options);
							w80739_a72fd5c7_2756_4a33_8f0c_5cbb7189f174.display();
						} catch (e) {
							console.log('err', e);
						}
					};
					let scr = d.getElementsByTagName(t)[0];
					let par = scr.parentNode;
					par.insertBefore(s, scr);
				})(document, 'script');
				window.onscroll = this.monitorSticky;
			} else {
				let w80739_44f12282_3fcb_4a67_9fe3_f7ae1d348dc8; // eslint-disable-line
				(function (d, t) {
					let host = 'widgets.bokun.io';
					let frameUrl = 'https://' + host + '/widgets/80739?bookingChannelUUID=94675dca-b020-42bb-9dae-b9ba24c0548b&amp;activityId=172346&amp;lang=zh&amp;ccy=ISK&amp;hash=w80739_44f12282_3fcb_4a67_9fe3_f7ae1d348dc8';
					let s = d.createElement(t);
					let options = {
						'host': host,
						'frameUrl': frameUrl,
						'widgetHash': 'w80739_44f12282_3fcb_4a67_9fe3_f7ae1d348dc8',
						'autoResize': true,
						'height': '',
						'width': '100%',
						'minHeight': 0,
						'async': true,
						'ssl': true,
						'affiliateTrackingCode': '',
						'transientSession': true,
						'cookieLifetime': 43200
					};
					s.src = 'https://' + host + '/assets/javascripts/widgets/embedder.js';
					s.onload = s.onreadystatechange = function () {
						var rs = this.readyState; if (rs) if (rs != 'complete') if (rs != 'loaded') return; // eslint-disable-line
						try {
							w80739_44f12282_3fcb_4a67_9fe3_f7ae1d348dc8 = new BokunWidgetEmbedder(); // eslint-disable-line
							w80739_44f12282_3fcb_4a67_9fe3_f7ae1d348dc8.initialize(options);
							w80739_44f12282_3fcb_4a67_9fe3_f7ae1d348dc8.display();
						} catch (e) {
							console.log('err', e);
						}
					};
					let scr = d.getElementsByTagName(t)[0];
					let par = scr.parentNode;
					par.insertBefore(s, scr);
				})(document, 'script');
				window.onscroll = this.monitorSticky;
			}
		});
	},
	methods: {
		monitorSticky: function () {
			let topAnchor = document.getElementById('anchor-top').getBoundingClientRect().top;
			let botAnchor = document.getElementById('anchor-bot').getBoundingClientRect().bottom;
			let bokunHeight = document.getElementById('bokun-container').clientHeight;
			let containerWidth = document.getElementsByClassName('right')[0].clientWidth;
			document.getElementsByClassName('container')[0].style.maxWidth = `${containerWidth}px`;
			// if below top anchor
			if (document.body.scrollTop > topAnchor + 140 || document.documentElement.scrollTop > topAnchor + 140) {
				// and above bot anchor
				if ((document.body.scrollTop + bokunHeight) < botAnchor || (document.documentElement.scrollTop + bokunHeight) < botAnchor) {
					this.sticky = true;
				} else {
					this.sticky = false;
				}
			} else {
				this.sticky = false;
			}
		}
	}
};
</script>

<style lang="less" scoped>
.tour {
	margin-bottom: 65px;
	.gutter {
		.left {
			margin-top: 143px;
			.content-block {
				margin-top: 63px;
				.title {
					font-size: 25px;
					font-weight: bold;
					margin-bottom: 42px;
				}
				.sub-title {
					font-weight: bold;
					font-size: 20px;
					margin-bottom: 32px;
				}
				.para {
					margin-bottom: 26px;
				}
			}
			img {
				width: 100%;
				max-height: 379px;
				object-fit: cover;
			}
		}
		.right {
			margin-top: 140px;
			width: 100%;
			position: relative;
			.container {
				width: 100%;
				@media screen and (min-width: 769px) {
					top: 140px;
					&.sticky {
						position: fixed;
					}
					.bokun {
						width: 100%;
					}
				}
			}
		}
	}
}
</style>
