import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VModal from 'vue-js-modal';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueMeta from 'vue-meta';
import VueAxios from 'vue-axios';
import axios from 'axios';
import VueCarousel from 'vue-carousel';
import HeaderSingleTour from '@/components/HeaderSingleTour.vue';
import BookNowBottom from '@/components/BookNowBottom';
import HeaderAbout from '@/components/HeaderAbout.vue';
import HeaderTours from '@/components/HeaderTours.vue';
import HeaderHome from '@/components/HeaderHome.vue';
import FullGrid from '@/components/FullGrid.vue';
import BlogGrid from '@/components/BlogGrid.vue';
import Horizon from '@/components/Horizon.vue';
import Privacy from '@/components/Privacy.vue';
import Cookies from '@/components/Cookies.vue';
import Footer from '@/components/Footer.vue';
import Terms from '@/components/Terms.vue';
import Grid from '@/components/Grid.vue';
import Side from '@/components/Side.vue';
import Nav from '@/components/Nav.vue';
Vue.component('HeaderSingleTour', HeaderSingleTour);
Vue.component('BookNowBottom', BookNowBottom);
Vue.component('HeaderAbout', HeaderAbout);
Vue.component('HeaderTours', HeaderTours);
Vue.component('HeaderHome', HeaderHome);
Vue.component('FullGrid', FullGrid);
Vue.component('BlogGrid', BlogGrid);
Vue.component('Horizon', Horizon);
Vue.component('Privacy', Privacy);
Vue.component('Cookies', Cookies);
Vue.component('Footer', Footer);
Vue.component('Terms', Terms);
Vue.component('Side', Side);
Vue.component('Grid', Grid);
Vue.component('Nav', Nav);

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueCarousel);
Vue.use(VueMeta);
Vue.use(VModal);
Vue.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyBnV7Bkg0bP4AI2Kj_1GUfanxuChpgOeEs'
	}
});

new Vue({
	router,
	store,
	watch: {
		'$route' (to) {
			if (to.meta.reload) {
				window.location.reload();
			}
		}
	},
	mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
	render: (h) => h(App)
}).$mount('#app');
