<template lang='pug'>
	.header
		img(src='@/assets/header.jpg' :alt='$store.state.about.title[$store.state.language]' :title='$store.state.about.title[$store.state.language]')
		.text-content
			h1.title.gutter {{ $store.state.about.title[$store.state.language] }}
			h2.sub-title.sans {{ $store.state.about.subTitle[$store.state.language] }}
</template>

<script>
export default {
	name: 'HeaderAbout',
	data: function () {
		return {
		};
	}
};
</script>

<style scoped lang='less'>
@color-main: #1728B0;
.header {
	background-color: #F8F8F8;
	position: relative;
	img {
		max-height: 253px;
		width: 100%;
		object-fit: cover;
		filter: brightness(.5);
		@media screen and (max-width: 1365px) {
			height: 544px;
		}
	}
	.text-content {
		box-sizing: border-box;
		position: absolute;
		width: 100%;
		top: 50%;
		padding-left: 24px;
		padding-right: 24px;
		transform: translate3d(0px, -50%, 0px);
		.title {
			font-size: 50px;
			text-align: center;
			font-weight: bold;
			color: white;
			@media screen and (max-width: 1365px) {
				font-size: 42px;
			}
		}
		.sub-title {
			font-weight: normal;
			margin-left: auto;
			margin-right: auto;
			max-width: 466px;
			font-size: 20px;
			text-align: center;
			color: white;
			@media screen and (max-width: 1365px) {
				font-size: 16px;
			}
		}
	}
}
</style>
