<template lang='pug'>
	.grid
		.gutter
			.grid-items
				.item(v-for='item in this.$store.state.blogs')
					.image-container
						img(:src='item.image' :alt='item.title[$store.state.language]' :title='item.title[$store.state.language]')
					.title {{ item.title[$store.state.language] }}
					.desc.sans {{ item.description[$store.state.language] }}
					.view-button.clickable(@click='routeTo(item.link)') {{ $store.state.blogGrid.read[$store.state.language] }}
</template>

<script>
export default {
	name: 'Grid',
	methods: {
		routeTo: function (destination) {
			let lang = 'en';
			if (this.$store.state.language === 'chinese') {
				lang = 'cn';
			}
			this.$router.push({
				name: destination,
				params: {
					language: lang
				}
			});
		}
	}
};
</script>

<style scoped lang='less'>
@color-main: #1728B0;
.grid {
	.gutter {
		.grid-items {
			margin-top: 86px;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-column-gap: 56px;
			@media screen and (max-width: 968px) {
				grid-template-columns: 1fr 1fr;
			}
			@media screen and (max-width: 768px) {
				grid-template-columns: 1fr;
			}
			.item {
				.image-container {
					position: relative;
					img {
						transition: .3s;
						width: 100%;
						height: 250px;
						object-fit: cover;
					}
				}
				.title {
					font-weight: bold;
					font-size: 20px;
					text-align: center;
				}
				.desc {
					font-size: 16px;
					text-align: center;
					margin-top: 14px;
					color: rgba(0,0,0,.7);
				}
				.view-button {
					margin-left: auto;
					margin-right: auto;
					margin-top: 18px;
					margin-bottom: 65px;
					border: 2px solid @color-main;
					width: 154px;
					height: 44px;
					font-size: 16px;
					font-weight: bold;
					text-align: center;
					line-height: 44px;
					color: black;
					transition: .3s;
					&:hover {
						color: white;
						background-color: @color-main;
					}
				}
			}
		}
		.top {
			padding-top: 100px;
			position: relative;
			height: 100px;
			.title {
				position: absolute;
				left: 0px;
				font-weight: bold;
				font-size: 25px;
			}
			.trips-button {
				position: absolute;
				right: 0px;
				.text {
					font-weight: bold;
					font-size: 16px;
				}
				.underline {
					transform: translate3d(0px, 10px, 0px);
					width: 100%;
					height: 3px;
					background-color: @color-main;
				}
			}
		}
	}
	@media screen and (max-width: 400px) {
		.gutter {
			.top {
				height: 120px;
				.title {
					position: relative;
				}
				.trips-button {
					display: inline-block;
					margin-top: 16px;
					position: relative;
				}
			}
		}
	}
}
</style>
