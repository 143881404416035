<template lang='pug'>
	.blog
		.description {{ $store.state.icelandicHorses.para1[$store.state.language] }}
		HeaderSingleTour(:image='"/blogs/1/1.jpg"' :title='$store.state.icelandicHorses.title[$store.state.language]')
		.gutter
			.content-block
				p.para.sans {{ $store.state.icelandicHorses.para1[$store.state.language] }}
				p.para.sans {{ $store.state.icelandicHorses.para2[$store.state.language] }}
				p.para.sans {{ $store.state.icelandicHorses.para3[$store.state.language] }}
				p.para.sans {{ $store.state.icelandicHorses.para4[$store.state.language] }}
				p.para.sans {{ $store.state.icelandicHorses.para5[$store.state.language] }}
				p.para.sans {{ $store.state.icelandicHorses.para6[$store.state.language] }}
</template>

<script>
export default {
	name: 'IcelandicHorses',
	computed: {
		abLang: function () {
			if (this.$store.state.language === 'english') {
				return 'en';
			} else {
				return 'cn';
			}
		}
	},
	metaInfo: function () {
		return {
			title: this.$store.state.icelandicHorses.title[this.$store.state.language],
			link: [
				{ rel: 'canonical', href: `https://n66.is/${this.abLang}/blog/icelandic_horses` }
			],
			meta: [
				{
					name: 'keywords',
					property: 'keywords',
					content: this.$store.state.seo.keywords[this.$store.state.language]
				},
				{
					name: 'og:site_name',
					property: 'og:site_name',
					content: 'New Horizons'
				},
				{
					name: 'og:url',
					property: 'og:url',
					content: `https://n66.is/${this.abLang}/blog/icelandic_horses/index.html`
				},
				{
					name: 'og:image',
					property: 'og:image',
					content: 'https://n66.is/blogs/1/1.jpg'
				},
				{
					name: 'og:title',
					property: 'og:title',
					content: this.$store.state.icelandicHorses.title[this.$store.state.language]
				},
				{
					name: 'og:description',
					property: 'og:description',
					content: this.$store.state.icelandicHorses.seo[this.$store.state.language]
				},
				{
					name: 'description',
					property: 'description',
					content: this.$store.state.icelandicHorses.seo[this.$store.state.language]
				}
			]
		};
	}
};
</script>

<style lang="less" scoped>
.blog {
	margin-bottom: 65px;
	.gutter {
		.content-block {
			margin-top: 63px;
			.title {
				font-size: 25px;
				font-weight: bold;
				margin-bottom: 42px;
			}
			.sub-title {
				font-weight: bold;
				font-size: 20px;
				margin-bottom: 32px;
			}
			.para {
				margin-bottom: 26px;
			}
		}
		img {
			width: 100%;
			max-height: 379px;
			object-fit: cover;
		}
	}
}
</style>
