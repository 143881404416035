<template lang='pug'>
	.contact
		.description {{ $store.state.seo.description[$store.state.language] }}
		.content
			.left
				h1.title {{ $store.state.contact.title[$store.state.language] }}
				.contact-form
					.top
						.section.first-name
							.label {{ $store.state.contact.firstName[$store.state.language] }}
							input.sans(type='text' :placeholder='$store.state.contact.firstName[$store.state.language]' v-model='contactForm.firstName')
						.section.last-name
							.label {{ $store.state.contact.lastName[$store.state.language] }}
							input.sans(type='text' :placeholder='$store.state.contact.lastName[$store.state.language]' v-model='contactForm.lastName')
						.section.last-name
							.label {{ $store.state.contact.email[$store.state.language] }}
							input.sans(type='text' :placeholder='$store.state.contact.email[$store.state.language]' v-model='contactForm.email')
						.section.last-name
							.label {{ $store.state.contact.phone[$store.state.language] }}
							input.sans(type='text' :placeholder='$store.state.contact.email[$store.state.language]' v-model='contactForm.phone')
					.bottom
						.section.message
							.label {{ $store.state.contact.message[$store.state.language] }}
							textarea.sans(:placeholder='$store.state.contact.yourMessage[$store.state.language]' v-model='contactForm.message')
						.send.clickable {{ $store.state.contact.send[$store.state.language] }}
			.right
				img(src='@/assets/contact-img.png' alt='icelandic sheep' title='icelandic sheep')
		gmap-map(:center="center" :zoom="15" style="width: 100%; height: 458px" :options="{ zoomControl: false, mapTypeControl: false, scaleControl: false, streetViewControl: false,rotateControl: false, fullscreenControl: false, disableDefaultUi: true }")
			gmap-marker(:position="position")
</template>

<script>
export default {
	name: 'Contact',
	data: function () {
		return {
			contactForm: {
				firstName: '',
				lastName: '',
				email: '',
				phone: '',
				message: ''
			},
			center: {
				lat: 64.1510169,
				lng: -21.9569395
			},
			position: {
				lat: 64.1510169,
				lng: -21.9569395
			}
		};
	},
	computed: {
		abLang: function () {
			if (this.$store.state.language === 'english') {
				return 'en';
			} else {
				return 'cn';
			}
		}
	},
	metaInfo: function () {
		return {
			title: 'New Horizons - Contact',
			link: [
				{ rel: 'canonical', href: `https://n66.is/${this.abLang}/contact/` }
			],
			meta: [
				{
					name: 'keywords',
					property: 'keywords',
					content: this.$store.state.seo.keywords[this.$store.state.language]
				},
				{
					name: 'og:site_name',
					property: 'og:site_name',
					content: 'New Horizons'
				},
				{
					name: 'og:url',
					property: 'og:url',
					content: `https://n66.is/${this.abLang}/contact/index.html`
				},
				{
					name: 'og:image',
					property: 'og:image',
					content: 'https://n66.is/header.jpg'
				},
				{
					name: 'og:title',
					property: 'og:title',
					content: 'New Horizons - Contact'
				},
				{
					name: 'og:description',
					property: 'og:description',
					content: this.$store.state.seoContact.description[this.$store.state.language]
				},
				{
					name: 'description',
					property: 'description',
					content: this.$store.state.seoContact.description[this.$store.state.language]
				}
			]
		};
	},
	methods: {
		sendForm: function () {
			this.axios.post('https://postalservice.jokula.is', {
				company: 'n66.is',
				name: `${this.contactForm.firstName} ${this.contactForm.lastName}`,
				phone: this.contactForm.phone,
				email: this.contactForm.email,
				message: this.contactForm.message
			}).then((result) => {
				alert('Message has been sent');
				this.contactForm.firstName = '';
				this.contactForm.lastName = '';
				this.contactForm.email = '';
				this.contactForm.phone = '';
				this.contactForm.message = '';
			});
		}
	}
};
</script>

<style lang="less" scoped>
@color-main: #1728B0;
.contact {
	background-color: #F8F8F8;
	.content {
		grid-template-columns: 1.5fr 1fr;
		display: grid;
		@media screen and (max-width: 768px) {
			grid-template-columns: 1fr;
		}
		.left {
			padding-top: 64px;
			padding-right: 128px;
			margin-left: 375px;
			@media screen and (max-width: 1374px) {
				padding-right: 64px;
				margin-left: 98px;
			}
			@media screen and (max-width: 520px) {
				padding-right: 0px;
				margin-left: 24px;
				margin-right: 24px;
			}
			.title {
				margin-bottom: 44px;
				font-size: 25px;
				font-weight: bold;
			}
			.contact-form {
				.top {
					display: grid;
					grid-template-columns: 1fr 1fr;
					grid-column-gap: 30px;
					grid-row-gap: 18px;
					@media screen and (max-width: 968px) {
						grid-template-columns: 1fr;
					}
					.section {
						.label {
							font-size: 16px;
							font-weight: bold;
							margin-bottom: 19px;
						}
						input {
							box-sizing: border-box;
							width: 100%;
							height: 50px;
							line-height: 50px;
							padding-left: 13px;
							padding-right: 13px;
							outline: none;
							border: none;
							font-size: 14px;
							outline: 1px solid rgba(0,0,0,0);
							transition: .3s;
							&::placeholder {
								color: #B7B7B7;
							}
							&:focus {
								outline: 1px solid @color-main;
							}
						}
					}
				}
				.bottom {
					padding-top: 18px;
					.send {
						display: inline-block;
						font-weight: bold;
						margin-top: 37px;
						padding-left: 32px;
						padding-right: 32px;
						height: 44px;
						line-height: 44px;
						outline: 2px solid @color-main;
						position: relative;
						left: 100%;
						transform: translate3d(-100%, 0px, 0px);
						margin-bottom: 48px;
					}
					.section {
						.label {
							font-size: 16px;
							font-weight: bold;
							margin-bottom: 19px;
						}
						textarea {
							box-sizing: border-box;
							width: 100%;
							height: 150px;
							line-height: 50px;
							padding-left: 13px;
							padding-right: 13px;
							resize: none;
							outline: none;
							border: none;
							font-size: 14px;
							outline: 1px solid rgba(0,0,0,0);
							transition: .3s;
							&::placeholder {
								color: #B7B7B7;
							}
							&:focus {
								outline: 1px solid @color-main;
							}
						}
					}
				}
			}
		}
		.right {
			@media screen and (max-width: 768px) {
				display: none;
			}
			height: 100%;
			img {
				transition: .3s;
				width: 100%;
				object-fit: cover;
				height: 100%;
				@media screen and (max-width: 968px) {
					object-position: center right;
				}
			}
		}
	}
}
</style>
