<template lang='pug'>
	.tours
		.description {{ $store.state.seo.description[$store.state.language] }}
		HeaderSingleTour(:title='$store.state.blogGrid.header[$store.state.language]')
		BlogGrid
</template>

<script>
export default {
	name: 'Home',
	computed: {
		abLang: function () {
			if (this.$store.state.language === 'english') {
				return 'en';
			} else {
				return 'cn';
			}
		}
	},
	metaInfo: function () {
		return {
			title: this.$store.state.blogGrid.header[this.$store.state.language],
			link: [
				{ rel: 'canonical', href: `https://n66.is/${this.abLang}/blog/` }
			],
			meta: [
				{
					name: 'keywords',
					property: 'keywords',
					content: this.$store.state.seo.keywords[this.$store.state.language]
				},
				{
					name: 'og:site_name',
					property: 'og:site_name',
					content: 'New Horizons'
				},
				{
					name: 'og:url',
					property: 'og:url',
					content: `https://n66.is/${this.abLang}/blog/index.html`
				},
				{
					name: 'og:image',
					property: 'og:image',
					content: 'https://n66.is/trips/1/header.jpg'
				},
				{
					name: 'og:title',
					property: 'og:title',
					content: this.$store.state.blogGrid.header[this.$store.state.language]
				},
				{
					name: 'og:description',
					property: 'og:description',
					content: this.$store.state.seoTours.description[this.$store.state.language]
				},
				{
					name: 'description',
					property: 'description',
					content: this.$store.state.seoTours.description[this.$store.state.language]
				}
			]
		};
	}
};
</script>

<style lang="less" scoped>
.tours {
	margin-bottom: 65px;
}
</style>
