<template lang='pug'>
	.grid
		.gutter
			.top
				.currency-select.clickable(:class='{ open: menuOpen }' @click='toggleMenu')
					.currency-item.selected
						.code {{ selectedCurrency }}
						img.img(src='/flags/ISK.svg' v-if='selectedCurrency === "ISK"' alt='ISK' title='ISK')
						img.img(src='/flags/CNY.svg' v-if='selectedCurrency === "CNY"' alt='CNY' title='CNY')
						img.img(src='/flags/EUR.svg' v-if='selectedCurrency === "EUR"' alt='EUR' title='EUR')
						img.img(src='/flags/USD.svg' v-if='selectedCurrency === "USD"' alt='USD' title='USD')
						img.img(src='/flags/GBP.svg' v-if='selectedCurrency === "GBP"' alt='GBP' title='GBP')
						img.img(src='/flags/JPY.svg' v-if='selectedCurrency === "JPY"' alt='JPY' title='JPY')
					.currency-item(@click='selectedCurrency = "ISK"' alt='ISK' title='ISK')
						.code ISK
						img.img(src='/flags/ISK.svg')
					.currency-item(@click='selectedCurrency = "CNY"' alt='CNY' title='CNY')
						.code CNY
						img.img(src='/flags/CNY.svg')
					.currency-item(@click='selectedCurrency = "EUR"' alt='EUR' title='EUR')
						.code EUR
						img.img(src='/flags/EUR.svg')
					.currency-item(@click='selectedCurrency = "USD"' alt='USD' title='USD')
						.code USD
						img.img(src='/flags/USD.svg')
					.currency-item(@click='selectedCurrency = "GBP"' alt='GBP' title='GBP')
						.code GBP
						img.img(src='/flags/GBP.svg')
					.currency-item(@click='selectedCurrency = "JPY"' alt='JPY' title='JPY')
						.code JPY
						img.img(src='/flags/JPY.svg')
			.grid-items
				.item(v-for='item in this.$store.state.trips')
					.image-container
						img(:src='item.image' :alt='item.title[$store.state.language]' :title='item.title[$store.state.language]')
						p.price-banner {{ $store.state.grid.priceFrom[$store.state.language] }} {{ getPrice(item.price) }} {{ selectedCurrency }}
						.price-hover
							p.top {{ $store.state.grid.priceFrom[$store.state.language] }}
							p.bot {{ getPrice(item.price) }}
					.content-container
						h4.title {{ item.title[$store.state.language] }}
						p.desc.sans {{ item.description[$store.state.language] }}
						.view-button.clickable(@click='routeTo(item.link)') {{ $store.state.grid.viewSingle[$store.state.language] }} <span class='caret'> > </span>
</template>

<script>
export default {
	name: 'Grid',
	data: function () {
		return {
			selectedCurrency: 'ISK',
			menuOpen: false
		};
	},
	methods: {
		convertCurrency: function (curr) {
			return curr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
		},
		getPrice: function (curr) {
			if (this.selectedCurrency !== 'ISK') {
				return this.convertCurrency(Math.round(curr / this.$store.state.currency[this.selectedCurrency]));
			} else {
				return this.convertCurrency(curr);
			}
		},
		toggleMenu: function () {
			this.menuOpen = !this.menuOpen;
		},
		routeTo: function (destination) {
			let lang = 'en';
			if (this.$store.state.language === 'chinese') {
				lang = 'cn';
			}
			this.$router.push({
				name: destination,
				params: {
					language: lang
				}
			});
		}
	}
};
</script>

<style scoped lang='less'>
@color-main: #1728B0;
.grid {
	.gutter {
		.grid-items {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-column-gap: 56px;
			@media screen and (max-width: 968px) {
				grid-template-columns: 1fr 1fr;
			}
			@media screen and (max-width: 768px) {
				grid-template-columns: 1fr;
			}
			.item {
				&:hover {
					.view-button {
						.caret {
							left: 18px;
						}
					}
				}
				.content-container {
					transform: translate3d(0px, -30px, 0px);
					background-color: white;
					box-shadow: 0 1px 4px 1px rgba(0,0,0,.1);
					margin-left: 10px;
					margin-right: 10px;
					padding-top: 12px;
					padding-bottom: 12px;
					padding-left: 16px;
					padding-right: 16px;
				}
				.image-container {
					position: relative;
					img {
						transition: .3s;
						width: 100%;
						height: 250px;
						object-fit: cover;
					}
					.price-hover {
						position: absolute;
						top: 30%;
						left: 50%;
						transform: translate3d(-50%, -50%, 0px);
						.top {
							font-weight: bold;
							font-size: 28px;
							color: white;
							text-align: center;
							height: 30px;
							opacity: 0;
							transition: .3s;
						}
						.bot {
							font-weight: bold;
							font-size: 55px;
							color: white;
							text-align: center;
							opacity: 0;
							transition: .3s;
						}
					}
					.price-banner {
						position: absolute;
						padding-left: 12px;
						padding-right: 12px;
						height: 33px;
						line-height: 33px;
						color: white;
						background-color: black;
						top: 14px;
						left: 0px;
						font-weight: bold;
						font-size: 16px;
						transition: .3s;
					}
				}
				.title {
					font-weight: bold;
					font-size: 18px;
					text-align: left;
				}
				.desc {
					font-size: 16px;
					text-align: left;
					margin-top: 14px;
					color: rgba(0,0,0,.7);
				}
				.view-button {
					margin-top: 18px;
					height: 44px;
					font-size: 16px;
					font-weight: bold;
					text-align: left;
					line-height: 44px;
					color: #00A6A8;
					transition: .3s;
					.caret {
						position: relative;
						top: 0px;
						left: 15px;
						transition: .3s;
						font-size: 21px;
						font-weight: normal;
					}
				}
				&:hover {
					.image-container {
						img {
							filter: brightness(.5);
						}
						.price-banner {
							opacity: 0;
						}
						.price-hover {
							.top {
								opacity: 1;
							}
							.bot {
								opacity: 1;
							}
						}
					}
				}
			}
		}
		.top {
			padding-top: 100px;
			position: relative;
			height: 100px;
			.currency-select {
				z-index: 30;
				position: absolute;
				right: 0px;
				transform: translate3d(0px, -13px, 0px);
				background-color: white;
				width: 110px;
				height: 50px;
				padding-bottom: 1px;
				@media screen and (max-width: 768px) {
					right: 0px;
					transform: translate3d(0px, -84px, 0px);
				}
				@media screen and (max-width: 400px) {
					right: 0px;
					transform: translate3d(0px, 3px, 0px);
				}
				.currency-item {
					transition: .3s;
					position: relative;
					left: 0px;
					top: 0px;
					height: 47px;
					padding-left: 10px;
					padding-right: 10px;
					display: grid;
					grid-template-columns: 1fr 1fr;
					grid-column-gap: 20px;
					background-color: white;
					border-left: 1px solid rgba(0,0,0,.1);
					border-right: 1px solid rgb(201, 201, 201);
					opacity: 0;
					pointer-events: none;
					&:hover {
						transform: scale(.95);
					}
					&.selected {
						pointer-events: initial;
						opacity: 1;
						background-color: white;
						border-left: 1px solid rgba(0,0,0,0);
						border-right: 1px solid rgba(0,0,0,0);
						border-bottom: 3px solid @color-main;
						padding-left: 0px;
						padding-right: 0px;
						.code {
							text-align: left;
						}
						img {
							position: absolute;
							right: 0px;
						}
						&:hover {
							transform: scale(1);
						}
					}
					.code {
						line-height: 50px;
						text-align: center;
						font-weight: bold;
					}
					img {
						outline: 1px solid rgba(0, 0, 0, .2);
						max-height: 20px;
						position: relative;
						top: 50%;
						transform: translate3d(0px, -50%, 0px);
					}

				}
				&.open {
					.currency-item {
						pointer-events: initial;
						opacity: 1;
					}
				}
			}
		}
	}
	@media screen and (max-width: 400px) {
		.gutter {
			.top {
				height: 120px;
				.title {
					position: relative;
				}
				.trips-button {
					display: inline-block;
					margin-top: 16px;
					position: relative;
				}
			}
		}
	}
}
</style>
