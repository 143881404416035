<template lang='pug'>
	#app
		Side(:active='side' @hide='hide')
		Nav(@showSide='showSide')
		.viewport
			router-view(:key='this.$store.state.language')
		Footer
		Privacy
		Terms
		Cookies
</template>

<script>
import { setTimeout } from 'timers';
export default {
	data: function () {
		return {
			side: false,
			blockClose: false
		};
	},
	watch: {
		'$route' (to, from) {
			if (to.params.language === 'cn') {
				this.$store.state.language = 'chinese';
			} else {
				this.$store.state.language = 'english';
			}
		}
	},
	created: function () {
		if (window.location.hostname !== 'cn.n66.is') {
			fetch('https://api.ipify.org').then((res) => {
				return res.text();
			}).then((ip) => {
				return fetch(`https://api.ipstack.com/${ip}?access_key=0808ff6d11816c66c8f17522d77b43f4`);
			}).then((res) => {
				return res.json();
			}).then((data) => {
				if (data.continent_name.includes('Asia')) {
					window.location.href = 'http://cn.n66.is';
				}
			}).catch((err) => {
				console.error('err', err);
			});
		}
		if (this.$route.params.language === 'cn') {
			this.$store.state.language = 'chinese';
		} else {
			if (window.location.href.includes('cn.n66')) {
				this.$store.state.language = 'chinese';
			} else {
				this.$store.state.language = 'english';
			}
		}
		this.axios.get('https://apis.is/currency/lb').then((result) => {
			let curr = {
				EUR: null,
				USD: null,
				GBP: null,
				JPY: null,
				CNY: null
			};
			result.data.results.forEach((currency) => {
				if (currency.shortName === 'EUR') {
					curr.EUR = currency.value;
				}
				if (currency.shortName === 'USD') {
					curr.USD = currency.value;
				}
				if (currency.shortName === 'GBP') {
					curr.GBP = currency.value;
				}
				if (currency.shortName === 'JPY') {
					curr.JPY = currency.value;
				}
				if (currency.shortName === 'CNY') {
					curr.CNY = currency.value;
				}
			});
			this.$store.state.currency = curr;
		}).catch((reason) => {});
	},
	methods: {
		showSide: function () {
			this.side = !this.side;
			this.blockClose = true;
			setTimeout(() => {
				this.blockClose = false;
			}, 300);
		},
		hide: function () {
			if (!this.blockClose) {
				this.side = false;
			}
		}
	}
};
</script>

<style lang="less">
#app {
	font-family: 'Titillium Web', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
	.gutter {
		margin-left: 372px;
		margin-right: 372px;
		@media screen and (min-width: 2312px) {
			max-width: 1920px;
			margin-left: auto;
			margin-right: auto;
		}
		@media screen and (max-width: 1374px) {
			margin-left: 98px;
			margin-right: 98px;
		}
		@media screen and (max-width: 520px) {
			margin-left: 24px;
			margin-right: 24px;
		}
	}
}
h1, h2, h3, h4, p, a {
	margin-top: 0px;
	margin-bottom: 0px;
	text-decoration: none;
}
.sans {
	font-family: 'Open Sans', sans-serif;
}
html, body {
	margin: 0px;
	position: relative;
	max-width: 100vw;
	overflow-x: hidden;
}
.clickable {
	cursor: pointer;
}
.viewport {
	margin-top: 122px;
	@media screen and (max-width: 520px) {
		margin-top: 87px;
	}
}
.description {
	opacity: 0;
	position: absolute;
}
</style>
