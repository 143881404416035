<template lang='pug'>
	.horizon
		.gutter
			.left
				h3.title {{ $store.state.horizon.header[$store.state.language] }}
				p.para.sans {{ $store.state.horizon.para[$store.state.language] }}
				.about-button.clickable(@click='routeTo("about")')
					p.text {{ $store.state.horizon.more[$store.state.language] }}
					.underline
			.right
				carousel(:perPage='1')
					slide(v-for='image, index in images' :key='index')
						img.slide-image(:src='image.url' :alt='image.alt' :title='image.alt')
</template>

<script>
export default {
	name: 'Horizon',
	data: function () {
		return {
			images: [
				{
					url: '/carousel/1.jpg',
					alt: 'volcano'
				},
				{
					url: '/carousel/2.jpg',
					alt: 'diving'
				},
				{
					url: '/carousel/3.jpg',
					alt: 'ice'
				},
				{
					url: '/carousel/4.jpg',
					alt: 'glacier'
				}
			]
		};
	},
	methods: {
		routeTo: function (destination) {
			let lang = 'en';
			if (this.$store.state.language === 'chinese') {
				lang = 'cn';
			}
			this.$router.push({
				name: destination,
				params: {
					language: lang
				}
			});
		}
	}
};
</script>

<style lang="less">
.VueCarousel-pagination {
	position: relative;
	max-width: 545px;
	left: 100%;
	transform: translate3d(-100%, 0px, 0px);
	@media screen and (max-width: 968px) {
		left: 50%;
		transform: translate3d(-50%, 0px, 0px);
	}
	.VueCarousel-dot {
		transition: .3s;
		background-color: rgba(0,0,0,.3) !important;
		outline: 0 !important;
		&.VueCarousel-dot--active {
			background-color: rgba(0,0,0,1) !important;
		}
	}
}
</style>

<style scoped lang='less'>
@color-main: #1728B0;
.horizon {
	margin-top: 65px;
	.gutter {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 60px;
		.left {
			position: relative;
			.title {
				font-weight: bold;
				font-size: 25px;
			}
			.para {
				margin-top: 42px;
				margin-bottom: 53px;
				font-size: 16px;
				color: rgba(0,0,0,.7);
			}
			.about-button {
				display: inline-block;
				.text {
					font-weight: bold;
					font-size: 16px;
				}
				.underline {
					transform: translate3d(0px, 10px, 0px);
					width: 100%;
					height: 3px;
					background-color: @color-main;
				}
			}
		}
		.right {
			position: relative;
			.slide-image {
				position: relative;
				width: 100%;
				left: 100%;
				transform: translate3d(-100%, 0px, 0px);
				max-width: 545px;
				height: 288px;
				object-fit: cover;
			}
		}
		@media screen and (max-width: 968px) {
			grid-template-columns: 1fr;
			grid-row-gap: 60px;
			.right {
				.slide-image {
					left: 50%;
					transform: translate3d(-50%, 0px, 0px);
				}
			}
		}
	}
}
</style>
